import WsProvider from './provider/WSProvider'

var token = localStorage.getItem("userDataDemirag");
var serviceName = "webservis";
var moduleName = "hizmet";


var hizmetService = {
    imagePath: WsProvider.imagePath,
    imagePathBuyuk: WsProvider.imagePathBuyuk,
    imagePathOrta: WsProvider.imagePathOrta,
    imagePathKucuk: WsProvider.imagePathKucuk,
    belgePath: WsProvider.documentPath,
    path: WsProvider.path,

    hizmetTanimla(baslik, icerik, resimKonum, menuKonum, galeriKonum, durum, seoBaslik, seoIcerik, resim) {
        var param = {
            baslik,
            icerik,
            resimKonum,
            menuKonum,
            galeriKonum,
            durum,
            seoBaslik,
            seoIcerik,
            seoKategori: 1,
            seoSorguTuru: 1,
            seoDurum: durum,
            seoSayac: 0,
            resim,
            token: token,
            serviceName: moduleName,
            methodName: "hizmetEkle"
        }
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result
    },

    hizmetTumListesi(durum, baslangic, limit) {
        var param = {
            durum: durum,
            baslangic,
            limit,
            token: token,
            serviceName: moduleName,
            methodName: "hizmetListele"
        }
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result
    },

    hizmetDetay(hizmetID) {
        var param = {
            hizmetID,
            token: token,
            serviceName: moduleName,
            methodName: "hizmetDetay"
        }
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result
    },

    hizmetAktifPasifSayisi() {
        var param = {
            token: token,
            serviceName: moduleName,
            methodName: "hizmetAktifPasifSayisi"
        }
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result
    },

    hizmetDurumGuncelle(hizmetID, durum) {
        var param = {
            hizmetID,
            durum,
            token: token,
            serviceName: moduleName,
            methodName: "hizmetDurumDegistir"
        }
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result
    },

    urunGuncelle(hizmetID, baslik, resim, icerik, resimKonum, menuKonum, galeriKonum, durum, seoBaslik, seoIcerik, link) {
        var param = {            
            hizmetID,            
            baslik,          
            resim,
            icerik,
            resimKonum,
            menuKonum,
            galeriKonum,
            durum,
            seoKategori: 1,
            seoSorguTuru: 1,
            seoDurum: durum,            
            seoSayac: 0,
            seoBaslik,
            seoIcerik,   
            link: link,
            token: token,
            serviceName: moduleName,
            methodName: "hizmetGuncelle"
        }
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result
    },    

    
}

export default hizmetService